
.collected-papers-page {
  .collected-papers-wrap {
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 30px;
    margin-bottom: 32px;

    .collected-papers {
      display: flex;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;

      .collected-info {
        padding-bottom: 28px;

        .img-collected {
          img {
            display: block;
            width: 250px;
            height: 356px;
            margin-right: 20px;
            box-shadow: 0px 2px 6px 0px rgba(15, 11, 5, 0.29);
          }
        }
      }

      .content-collected {
        h5 {
          font-size: 20px;
          line-height: 22px;
          font-weight: bold;
          display: flex;
          // justify-content: space-between;
          div {
            display: flex;
            align-items: center;
            line-height: 20px;
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
            // img {
            //     width: 17px;
            //     height: 17px;
            //     margin-right: 8px;
            // }
            .included-database {
              span {
                float: left;
                height: 22px;
                margin-left: 10px;
                display: block;
                font-size: 12px;
                color: #BD1A2D;
                padding: 0 10px;
                line-height: 22px;
                border: 0.8px solid #A91728;
                box-sizing: border-box;
                border-radius: 10px;
                margin-bottom: 5px;
                white-space: nowrap;
              }
            }
          }
        }

        .content-collected-wrap {
          display: flex;

          .left-collected {
            width: 706px;

            div {
              margin-top: 19px;
              line-height: 17px;
              font-size: 16px;
              color: #666;

              p {
                font-size: 16px;
                color: #666;
              }

              span {
                font-size: 16px;
                color: #333;
              }

              p:first-child {
                width: 400px;
              }
            }

            .com-flex {
              display: flex;
            }

            .abstract {
              width: 706px;
              color: #666;
              line-height: 35px;
              margin-top: 14px;
              word-break: break-all;

              span {
                color: #666;
              }

              // text-overflow: -o-ellipsis-lastline;
              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 5;
              // line-clamp: 5;
              // -webkit-box-orient: vertical;
            }
          }

          .right-collected {
            width: 116px;
            margin-left: 46px;
            padding-top: 24px;

            .er-wrap {
              width: 116px;
              height: 116px;
              background: url(../assets/img/erwrap.png);
              background-size: 100% 100%;
              padding-top: 10px;
              box-sizing: border-box;

              .er {
                width: 96px;
                height: 96px;
                margin-bottom: 4px;
                margin-left: 10px;
              }

              p {
                text-align: center;
                color: #bd1a2d;
                font-size: 12px;
                line-height: 12px;
              }
            }

            img.contribution {
              width: 116px;
              height: 58px;
              margin: 26px 0 14px;
            }

            img.propaganda {
              width: 116px;
              height: 58px;
            }
          }
        }
      }
    }

    .collected-msg {
      display: flex;
      justify-content: space-between;

      ul.left-msg {
        margin-right: 20px;

        li {
          width: 250px;
          height: 56px;
          line-height: 56px;
          text-align: center;
          box-sizing: border-box;
          border: 1px solid #e6e6e6;
          font-size: 16px;
          cursor: pointer;
        }

        li.active {
          color: #fff;
          background: #bd1a2d;
          border: 1px solid #bd1a2d;
        }
      }

      .right-msg {
        width: 868px;
        min-height: 392px;
        // background: #bd1a2d;
        ul.issue {
          display: flex;
          flex-wrap: wrap;

          li {
            width: 128px;
            height: 82px;
            margin-right: 20px;
            margin-bottom: 20px;
            background: #dabc85;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-sizing: border-box;
            padding-top: 18px;
            cursor: pointer;

            span {
              display: block;
              color: #fff;
              font-size: 16px;
            }
          }

          li:nth-child(6n + 6) {
            margin-right: 0;
          }
        }

        ul.right-msg-info {
          h5 {
            font-size: 16px;
            color: #333;
            line-height: 38px;
            font-weight: bold;
            margin: 20px 0 8px;
          }
          div.bot-wrap {
            display: flex;

            align-items: center;
            position: relative;
            line-height: 38px;
            justify-content: space-between;
            background: url(../assets/img/border.png);
            background-position: 6% 49%;
            background-repeat: repeat-x;
            background-size: 1% 1px;
            margin-bottom: 12px;

            div {
              display: flex;
              padding-left: 6px;
              font-size: 16px;
              color: #333;
              background: #fff;
              padding-right: 7px;
              cursor: pointer;

              p {
                font-size: 16px;
                color: #333;
              }
            }

            .big-p {
              max-width: 525px;
              line-height: 26px;
            }

            a {
              font-size: 16px;
              color: #666;
              background: #fff;
              padding-left: 5px;
              display: block;
              line-height: 26px;
              cursor: auto;
            }
          }

          div.bot-wrap::before {
            content: '';
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ffac13;
            position: absolute;
            left: -4px;
            top: 12px;
          }
        }
      }
    }
  }

  .hot-article-wrap {
    width: 1200px;
    margin-bottom: 30px;
    padding: 10px 30px;
    box-sizing: border-box;
    border: 1px solid #f5f5f5;
    box-shadow: 0px 5px 8px 1px rgba(0, 0, 0, 0.1);

    .hot-article {
      margin-bottom: 13px;
      display: flex;
      flex-wrap: wrap;
      background: url(../assets/img/border1.png);
      background-size: 1px 50%;
      background-repeat: repeat-y;
      background-position: 50% 0%;
      margin-top: 20px;

      li {
        width: 569px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 20px;

        p {
          font-size: 18px;
          padding-left: 10px;
          box-sizing: border-box;
          position: relative;
          cursor: pointer;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #333;

          span {
            color: #666;
          }
        }

        p::before {
          content: '';
          width: 4px;
          height: 4px;
          display: block;
          position: absolute;
          left: 0;
          top: 8px;
          border-radius: 50%;
          background: #ffac13;
        }

        span {
          width: 164px;
          text-align: left;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      li:nth-child(2n + 1) {
        p {
          width: 385px;
        }
      }

      li:nth-child(2n + 2) {
        p {
          width: 358px;
        }
      }

      li:nth-child(2n + 2) {
        box-sizing: border-box;
        padding-left: 35px;
      }

      li:nth-child(2n + 2)::before {
        left: 39px;
      }

      li:last-child {
        margin-bottom: 0;
      }

      li:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }
}

.print_box {
  display: none;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  @page {
    size: 8.5in 11.5in;
    margin: 1.5cm 2cm;
  }
  .PageNext {
    page-break-after: always;
  }
  .print_box {
    /*padding: 30mm 18mm;*/
    /*display: none;*/
    color: #000;
    font-size: 14pt;
    background: #333000;
  }
  .page_break .fl {
    width: 320pt;
  }
  .page_last .fl {
    width: 210pt;
  }
  .page_break .title {
    font-size: 25.5pt;
    line-height: 40pt;
    margin-bottom: 20pt;
    font-weight: bold;
  }
  .resouce {
    font-size: 13.5pt;
    line-height: 20pt;
    margin-bottom: 20pt;
    font-weight: bold;
  }
  .mian {
    line-height: 20pt;
    margin-bottom: 20pt;
  }
  .blue {
    padding-left: 20pt;
    line-height: 30pt;
    margin-bottom: 10pt;
    font-size: 14.2pt;
    color: #2987cb !important;
  }
  .blue > span {
    float: left;
    margin-left: -20pt;
    line-height: 30pt;
    color: #2987cb !important;
  }
  .abs {
    width: 320pt;
    font-size: 13.8pt;
    margin-top: 30pt;
    line-height: 28pt;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    flex-direction: column;

    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
    // -webkit-box-orient: vertical;
  }
  .page_break .fr {
    width: 150pt;
  }
  .page_last .fr {
    width: 276pt;
  }
  .print_img {
    width: 144pt;
    height: 210pt;
    border: 1pt solid #000;
    box-shadow: 5px 5px 5px #666 !important;
    margin-bottom: 20pt;
  }
  .print_num {
    text-align: center;
    margin-bottom: 15pt;
  }
  .fr .blue {
    text-align: center;
  }
  .fr .blue.mrt60 {
    margin-top: 50pt;
  }
  .page_break {
    margin-bottom: 30pt;
  }
  .iedolCode {
    width: 100%;
    height: 118pt;
  }
  .logo_jk {
    float: right;
    margin-top: 36pt;
    width: 57pt;
    height: 72pt;
  }
  .iedol_logo img {
    width: 100pt;
    height: 100pt;
    margin-bottom: 10pt;
  }
  .iedol_logo p {
    width: 105pt;
    text-align: center;
    letter-spacing: 4pt;
    font-size: 14pt;
  }
  .page_last h5 {
    text-align: center;
    font-size: 15pt;
    line-height: 30pt;
    font-weight: bold;
    letter-spacing: 5pt;
    margin-bottom: 30pt;
  }
  .new_list {
    width: 200pt;
    margin-top: 10pt;
    padding: 0 10pt;
    padding-top: 5pt;
    border: 3pt solid #909a83;
  }
  .new_list > h4 {
    text-align: center;
    font-size: 13.5pt;
    line-height: 30pt;
    font-weight: bold;
    margin-bottom: 10pt;
    letter-spacing: 7.5pt;
    color: #000;
  }
  .new_list li h5 {
    letter-spacing: 0;
    text-align: left;
    font-size: 13.5pt;
    line-height: 20pt;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
    margin-bottom: 5pt;
    font-weight: bold;
    color: #000;
  }
  .new_list li h6 {
    font-size: 10pt;
    line-height: 20pt;
    margin-bottom: 5pt;
    color: #000;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
  }
  .new_list li p {
    font-size: 10pt;
    line-height: 20pt;
    margin-bottom: 5pt;
    font-style: italic;
    color: #333;
    width: 180pt;
    overflow: hidden; /*超出部分隐藏*/
    text-overflow: ellipsis; /* 超出部分显示省略号 */
    white-space: nowrap; /*规定段落中的文本不进行换行 */
  }
  .ssap_logo {
    width: 70pt;
    height: 70pt;
    margin-top: 10pt;
  }
  .content_gy {
    height: 640pt;
    overflow: hidden; /*超出部分隐藏*/
  }
  .content_gy > > > p {
    font-size: 9pt;
    line-height: 21pt;
    margin-bottom: 14pt;
    color: #6e6e6e !important;
  }
}
